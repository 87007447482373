<template>
    <div class="docs">
        <h1>Dokumente und Links</h1>

        <div class="row">
            <div class="col-12">
                <p>
                    <span class="section-title">Positionspapiere und Stellungnahmen</span>
                </p>

                <p class="list">
                    <span><a href="https://musikforschung.de/index.php/memoranda/schaffung-nationaler-forschungsdateninfrastrukturen-nfdi/langfassung" target="_blank">Memorandum der Gesellschaft für Musikforschung zur Schaffung nationaler Forschungsdateninfrastrukturen (NFDI)</a></span><br/>
                    <span class="section-info">in <a href="https://musikforschung.de/index.php/memoranda/schaffung-nationaler-forschungsdateninfrastrukturen-nfdi/kurzfassung" target="_blank">Kurzfassung</a> und <a href="https://musikforschung.de/index.php/memoranda/schaffung-nationaler-forschungsdateninfrastrukturen-nfdi/langfassung" target="_blank">Langfassung</a>, Gesellschaft für Musikforschung, Januar 2018</span>
                </p>

                <p>&nbsp;</p>

                <p class="list">
                    <span><a href="http://www.dfg.de/service/presse/pressemitteilungen/2018/pressemitteilung_nr_58/index.html" target="_blank">Nationale Forschungsdateninfrastruktur (NFDI): DFG übernimmt Auswahl und Evaluation der Konsortien</a></span><br/>
                    <span class="section-info">Deutsche Forschungsgemeinschaft (DFG), Pressemitteilung Nr. 58, 7. Dezember 2018</span>
                </p>

                <p class="list">
                    <span><a href="http://www.hsozkult.de/debate/id/diskussionen-4643" target="_blank">Historikertag 2018: Digitale Geschichte</a></span><br/>
                    <span class="section-info">Historikertag 2018: Digitale Geschichte, in: H-Soz-Kult, 6. Dezember 2018</span>
                </p>

                <p class="list">
                    <span><a href="https://www.faz.net/aktuell/feuilleton/hoch-schule/chancen-der-nationalen-forschungsdatensammlung-15914287.html" target="_blank">Im Datenozean – Chancen der nationalen Forschungsdatensammlung</a></span><br/>
                    <span class="section-info">Torsten Schrade, Frankfurter Allgemeine Zeitung, 29. November 2018</span>
                </p>

                <p class="list">
                    <span><a href="https://www.faz.net/aktuell/feuilleton/hoch-schule/geisteswissenschaften-wohin-mit-den-daueraufgaben-15819540.html" target="_blank">Wohin mit den Daueraufgaben?</a></span><br/>
                    <span class="section-info">Martin Grötschel , Wilhelm Krull und Christoph Markschies, Frankfurter Allgemeine Zeitung, Oktober 2018</span>
                </p>

                <p class="list">
                    <span><a href="https://forschungsinfrastrukturen.de/doku.php/zusammenfassung-2018-10-04" target="_blank">Zusammenfassung 04.10.2018</a></span><br/>
                    <span class="section-info">Workshop Wissenschaftsgeleitete Forschungsinfrastrukturen für die Geistes- und Kulturwissenschaften in Deutschland: Politische Perspektive, Berlin, 4. Oktober 2018</span>
                </p>

                <p class="list">
                    <span><a href="https://doi.org/10.5281/zenodo.1442845" target="_blank">Vorschlag der AG Datenzentren im DHd zur Bildung und Strukturierung eines NFDI-Konsortiums für die Geisteswissenschaften</a></span><br/>
                    <span class="section-info">AG Datenzentren des DHd, 2. Oktober 2018</span>
                </p>

                <p class="list">
                    <span><a href="https://wikis.sub.uni-hamburg.de/webis/images/a/a1/AG_FID_zu_NFDI.pdf" target="_blank">Gemeinsame Stellungnahme der FID leitenden Einrichtungen und der AG Fachinformationsdienste (AG FID) zur Einbindung von Fachinformationsdiensten in den Aufbau einer Nationalen Forschungsdateninfrastruktur</a></span><br/>
                    <span class="section-info">Positionspapier der AG Fachinformationsdienste (FID) der DBV Sektion 4, 26. September 2018</span>
                </p>

                <p class="list">
                    <span><a href="http://mainzed.org/empfehlungen/Daten_in_Forschung_Lehre_und_Transfer_I1.pdf" target="_blank">Daten in Forschung, Lehre und Transfer</a></span><br/>
                    <span class="section-info">Empfehlungen des Mainzer Zentrums für Digitalität in den Geistes- und Kulturwissenschaften (mainzed), Mainz, 13. Juni 2018</span>
                </p>

                <p class="list">
                    <span><a href="http://www.dfg.de/download/pdf/foerderung/programme/lis/180522_awbi_impulspapier.pdf" target="_blank">Stärkung des Systems wissenschaftlicher Bibliotheken in Deutschland</a></span><br/>
                    <span class="section-info">Impulspapier des Ausschusses für Wissenschaftliche Bibliotheken und Informationssysteme der Deutschen Forschungsgemeinschaft, Bonn, 22. Mai 2018</span>
                </p>

                <p class="list">
                    <span><a href="https://doi.org/10.5281/zenodo.1217527" target="_blank">Zur Rolle der Hochschulen</a></span><br/>
                    <span class="section-info">Positionspapier der Landesinitiative NFDI und Expertengruppe FDM der Digitalen Hochschule NRW zum Aufbau einer Nationalen Forschungsdateninfrastruktur, April 2018</span>
                </p>


                <p class="list">
                    <span><a href="http://www.dfg.de/download/pdf/foerderung/programme/lis/positionspapier_informationsinfrastrukturen.pdf" target="_blank">Förderung von Informationsinfrastrukturen für die Wissenschaft</a></span><br/>
                    <span class="section-info">Positionspapier der Deutschen Forschungsgemeinschaft (DFG), Bonn, 15. März 2018</span>
                </p>



                <p class="list">
                    <span><a href="https://www.akademienunion.de/fileadmin/redaktion/user_upload/Publikationen/Positionspapiere/NFDI-Positionspapier_Akademienunion_01.pdf" target="_blank">Positionspapier der Union der deutschen Akademien der Wissenschaften zur Schaffung einer Nationalen Forschungsdateninfrastruktur (NFDI)</a></span><br/>
                    <span class="section-info">Positionspapier der Union der deutschen Akademien der Wissenschaften Februar 2018</span>
                </p>

                <p class="list">
                    <span><a href="https://www.bibliotheksverband.de/fileadmin/user_upload/Sektionen/sektion4/Publikationen/WB2025_Endfassung_endg.pdf" target="_blank">Wissenschaftliche Bibliotheken 2025</a></span><br/>
                    <span class="section-info">Positionspapier der Wissenschaftliche Universalbibliotheken (Sektion 4 der DBV), Januar 2018</span>
                </p>

                <p class="list">
                    <span><a href="https://dig-hum.de/stellungnahme-dhd-nfdi" target="_blank">Stellungnahme des Verbandes Digital Humanities im deutschprachigen Raum (DHd) zur Nationalen Forschungsdateninfrastruktur (NFDI)</a></span><br/>
                    <span class="section-info">Verband Digital Humanities im deutschsprachigen Raum (DHd), Ursprüngliche Fassung vom 31. Juli. 2017, aktualisiert am 18. Januar 2018</span>
                </p>

                <p class="list">
                    <span><a href="https://doi.org/10.5281/zenodo.1134760" target="_blank">Geisteswissenschaftliche Datenzentren im deutschsprachigen Raum</a></span><br/>
                    <span class="section-info">Grundsatzpapier zur Sicherung der langfristigen Verfügbarkeit von Forschungsdaten, AG Datenzentren des DHd, 3. Januar 2018</span>
                </p>
            </div>
            <div class="section col-12">
                <p>
                    <span class="section-title">Rat für Informationsinfrastrukturen (RfII)</span>
                </p>

                <p class="list">
                    <span><a href="http://www.rfii.de/?p=3509" target="_blank">In der Breite und forschungsnah: Handlungsfähige Konsortien.</a></span><br/>
                    <span class="section-info">Dritter Diskussionsimpuls zur Ausgestaltung einer Nationalen Forschungsdateninfrastruktur (NFDI) für die Wissenschaft in Deutschland, Göttingen Dezember 2018, 5 S.</span>
                </p>
                <p class="list">
                    <span><a href="http://www.rfii.de/?p=2529" target="_blank">Zusammenarbeit als Chance.</a></span><br/>
                    <span class="section-info">Zweiter Diskussionsimpuls zur Ausgestaltung einer Nationalen Forschungsdateninfrastruktur (NFDI) für die Wissenschaft in Deutschland, Göttingen März 2018, 4 S.</span>
                </p>
                <p class="list">
                    <span><a href="http://www.rfii.de/?p=2346" target="_blank">Entwicklung von Forschungsdateninfrastrukturen im internationalen Vergleich</a></span><br/>
                    <span class="section-info">Bericht und Anregungen, Göttingen 2017, 93 S.</span>
                </p>
                <p class="list">
                    <span><a href="http://www.rfii.de/?p=2269" target="_blank">Schritt für Schritt – oder: Was bringt wer mit?</a></span><br/>
                    <span class="section-info">Ein Diskussionsimpuls zu Zielstellung und Voraussetzungen für den Einstieg in die Nationale Forschungsdateninfrastruktur (NFDI), Göttingen April 2017, 4 S.</span>
                </p>
                <p class="list">
                    <span><a href="http://www.rfii.de/?p=2253" target="_blank">Datenschutz und Forschungsdaten</a></span><br/>
                    <span class="section-info">Aktuelle Empfehlungen, Göttingen 2017, 35 S.</span>
                </p>
                <p class="list">
                    <span><a href="http://www.rfii.de/?p=1998" target="_blank">Leistung aus Vielfalt</a></span><br/>
                    <span class="section-info">Empfehlungen zu Strukturen, Prozessen und Finanzierung des Forschungsdatenmanagements in Deutschland, Göttingen 2016, 160 S.</span>
                </p>
            </div>
            <div class="section col-12">
                <p>
                    <span class="section-title">Gemeinsame Wissenschaftskonferenz</span>
                </p>

                <p class="list">
                    <span><a href="https://www.gwk-bonn.de/themen/weitere-arbeitsgebiete/informationsinfrastrukturen-nfdi/" target="_blank">Bund-Länder-Vereinbarung zu Aufbau und Förderung einer Nationalen Forschungsdateninfrastruktur (NFDI)</a></span><br/>
                    <span class="section-info"><a href="https://www.gwk-bonn.de/fileadmin/Redaktion/Dokumente/Papers/NFDI.pdf" target="_blank">Vereinbarung direkt als PDF</a>, Berlin/Bonn, 26. November 2018</span>
                </p>

                <p class="list">
                    <span><a href="https://www.gwk-bonn.de/fileadmin/Redaktion/Dokumente/Pressemitteilungen/pm2018-14.pdf" target="_blank">Förderung von Forschungsbauten, Großgeräten und Nationalem Hochleistungsrechnen – Aufbau einer Infrastruktur für die Hochschulen (PDF)</a></span><br/>
                    <span class="section-info">Pressemitteilung 14/2018, Berlin/Bonn, 16. November 2018</span>
                </p>
                <p class="list">
                    <span><a href="https://www.gwk-bonn.de/fileadmin/Redaktion/Dokumente/Pressemitteilungen/pm2018-13.pdf" target="_blank">Forschungsdaten nachhaltig sichern und nutzbar machen – Startschuss für eine Nationale Forschungsdateninfrastruktur (PDF)</a></span><br/>
                    <span class="section-info">Pressemitteilung 13/2018, Berlin/Bonn, 16. November 2018</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'docs',
        components: {
        }
    }
</script>

<style scoped>

    h1 {
        margin-bottom: 4ex;
        text-align: center;
    }

    .docs {
        background-color: #ffffff;
        border-radius: 0 0 7px 7px;
        padding: 20px;
        color: #1b1e21;
        text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .2);
        z-index: 5;
        text-align: left;
    }

    div.section {
        padding-top: 30px;
    }

    .section-title {
        font-weight: bold;
        font-size: larger;
    }

    p.list {
        padding-left: 2.5ex;
    }

    p.heading {
        margin-top: 2rem;
        margin-bottom: 0.25rem;
    }

    .section-label {
        font-style: italic;
    }

    .section-info {
        color: #666;
        padding-left: 1.7rem !important;
        font-size: smaller;
    }

</style>