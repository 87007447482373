<template>
    <div class="program">
        <h1>Programm</h1>

        <div class="row">
            <div class="col-12">
                <p>
                    <span class="day-label">Donnerstag, 13. Dezember, 13.00 – 18.30 Uhr:</span>
                </p>

                <p>
                    <span class="section-label">Begrüßung</span><br/>
                    <span class="section-moderator">Dipl. Wirt.-Inf. Daniel Röwenstrunk, Zentrum Musik – Edition – Medien, Universität Paderborn</span><br/>
                    <span class="section-moderator">Prof. Dr. Joachim Veit, Virtueller Forschungsverbund Edirom, Universität Paderborn</span>
                </p>

                <p class="heading">
                    <span class="section-label">Grußworte</span>
                </p>
                <p class="list">
                    <span>Prof. Dr. Birgitt Riegraf, Präsidentin der Universität Paderborn</span><br/>
                    <span>Prof. Dr. Dörte Schmidt, Präsidentin der Gesellschaft für Musikforschung</span><br/>
                    <span>Dr. Ania López, Projektleiterin der Landesinitiative NFDI der Digitalen
Hochschule NRW</span>
                </p>

                <p class="heading">
                    <span class="section-label">Einführung in die Thematik und Berichte zum bisherigen Diskussionsstand</span><br/>
                    <span class="section-moderator">ca. 60 Minuten</span>
                </p>
                <p class="list">
                    <span>Tagesordnung</span><br/>
                    <span class="speaker">Prof. Dr. Joachim Veit, Virtueller Forschungsverbund Edirom, Universität Paderborn</span><br/>
                    <span>Bericht des Rats für Informationsinfrastrukturen</span><br/>
                    <span class="speaker">Prof. Dr. Dr. Friederike Fless, Rat für Informationsinfrastrukturen, Deutsches Archäologisches Institut, Freie Universität Berlin</span><br/>
                    <span>Bericht zum Stand des NFDI-Prozesses</span><br/>
                    <span class="speaker">Dr. Ania López, Landesinitiative NFDI der Digitalen Hochschule NRW, Universität Duisburg-Essen</span><br/>
                    <span>Einführung in die Pläne zur Gründung einer <em>NFDI4Culture</em></span><br/>
                    <span class="speaker">Prof. Dr. Dörte Schmidt, Gesellschaft für Musikforschung, Bernd-Alois-Zimmermann-Gesamtausgabe, Universität der Künste Berlin</span><br/>
                    <span>Ebenen von und Prozesse in Infrastrukturen</span><br/>
                    <span class="speaker">Dipl. Wirt.-Inf. Daniel Röwenstrunk, Zentrum Musik – Edition – Medien, Universität Paderborn</span>
                </p>

                <p class="heading">
                    <span class="section-label">Pause (14.30 – 15.00 Uhr)</span>
                </p>

                <p class="heading">
                    <span class="section-label">Sektion 1: Impulsreferate und Diskussion zu Forschungsdaten aus unterschiedlichen Bereichen des musikbezogenen Kulturerbes in Bezug auf Forschungsgegenstände und Standards zu deren digitaler Erfassung</span><br/>
                    <span class="section-moderator">Moderation: Prof. Dr. Gudrun Oevel, Zentrum für Informations- und Medientechnologien (IMT), Universität Paderborn, ca. 90 Minuten</span>
                </p>
                <p class="list">
                    <span>Musikinstrumentensammlungen</span><br/>
                    <span class="speaker">Dr. Heike Fricke, BMBF-Projekt TASTEN, Museum für Musikinstrumente Leipzig</span><br/>
                    <span>Musikethnologie</span><br/>
                    <span class="speaker">Dr. Ricarda Kopal, Ethnologisches Museum Berlin</span><br/>
                    <span>Music Information Retrieval</span><br/>
                    <span class="speaker">Prof. Dr. Aristotelis Hadjakos, Zentrum Musik- und Filminformatik, Hochschule für Musik Detmold</span><br/>
                    <span>Editionsphilologie</span><br/>
                    <span class="speaker">Peter Stadler M.A., Carl-Maria-von-Weber-Gesamtausgabe, Universität Paderborn</span><br/>
                    <span>Musikbezogene Metadaten</span><br/>
                    <span class="speaker">Dr. Ann Kersting-Meuleman, Sammlung Musik und Theater der Universitätsbibliothek Frankfurt/Main, IAML Deutschland</span>
                </p>

                <p class="heading">
                    <span class="section-label">Pause (16.30 – 17.00 Uhr)</span>
                </p>

                <p class="heading">
                    <span class="section-label">Sektion 2: Podiumsdiskussion zu Infrastrukturen: Bedarfe, existierende Angebote, Herausforderungen, rechtliche Rahmenbedingungen, Ebenen von Infrastrukturen und Rollen und Verantwortung einzelner Institutionen im Digitalisierungs-, Forschungs- und Kurationsprozess (Lebenszyklen) von Daten</span><br/>
                    <span class="section-moderator">Moderation: Dipl. Wirt.-Inf. Daniel Röwenstrunk / Prof. Dr. Joachim Veit, ca. 90 Minuten</span>
                </p>
                <p class="list">
                    <span>Peter Gietz M.A.</span><br/>
                    <span class="speaker">DAASI International, Vorstand DHd, DARIAH-DE</span><br/>
                    <span>Dr. Reiner Nägele</span><br/>
                    <span class="speaker">Musikabteilung Bayerische Staatsbibliothek und FID Musik</span><br/>
                    <span>Prof. Dr. Gudrun Oevel</span><br/>
                    <span class="speaker">Zentrum für Informations- und Medientechnologien (IMT), Universität Paderborn</span><br/>
                    <span>Johannes Sauter M.A.</span><br/>
                    <span class="speaker">Deutsches Museum Digital, KultSam</span><br/>
                    <span>Prof. Dr. Dörte Schmidt</span><br/>
                    <span class="speaker">Gesellschaft für Musikforschung, Bernd-Alois-Zimmermann-Gesamtausgabe, Universität der Künste Berlin</span><br/>
                    <span>Prof. Torsten Schrade</span><br/>
                    <span class="speaker">Digitale Akademie der Akademie der Wissenschaften und der Literatur Mainz, Hochschule Mainz</span><br/>
                    <span>Prof. Dr. Nicole Schwindt</span><br/>
                    <span class="speaker">Staatliche Hochschule für Musik Trossingen, RISM</span>
                </p>


                <p class="list">
                    Zur Beantwortung rechtlicher Fragen steht an diesem Nachmittag Prof. Dr. Christian Czychowski (Rechtsanwalt / Fachanwalt für IT, Urheber- u. Medienrecht) zur Verfügung.
                </p>

                <p class="heading">
                    <span class="section-label">Gemeinsamens Abendessen im Welcome Hotel (ab 19.00 Uhr)</span>
                </p>

                <p style="margin-top: 3em;">
                    <span class="day-label">Freitag, 14. Dezember, 9.00 – 13.00 Uhr:</span>
                </p>

                <p class="heading">
                    <span class="section-label">Sektion 3: Konsortienbildung und Kooperation mit anderen Konsortien: Blick der möglichen Konsortiumspartner auf die bisherige Diskussion und Identifikation fächerübergreifender Gemeinsamkeiten zur Schärfung eines Konsortiumprofils; Spezifika eines denkbaren Konsortiums “NFDI4Culture” und Schnittstellen zu anderen Konsortien</span><br/>
                    <span class="section-moderator">Moderation: Dipl. Wirt.-Inf. Daniel Röwenstrunk, ca. 90 Minuten</span>
                </p>
                <p class="list">
                    <span>Archäologie</span><br/>
                    <span class="speaker">Prof. Dr. Reinhard Förtsch, Deutsches Archäologisches Institut</span><br/>
                    <span>Kunstgeschichte</span><br/>
                    <span class="speaker">Dr. Georg Schelbert, Institut für Kunst- und Bildgeschichte, Humboldt-Universität zu Berlin</span><br/>
                    <span>Beobachtungen zur Konsortiumsbildung im Fach Geschichte</span><br/>
                    <span class="speaker">Dr. Martin Dröge, Neueste Geschichte, Universität Paderborn</span><br/>
                    <span>Digital Humanities im deutschsprachigen Raum (DHd)</span><br/>
                    <span class="speaker">Peter Gietz M.A., DAASI International, Vorstand DHd, DARIAH-DE</span><br/>
                    <span>NFDI-Konsortium zu Editionen, Textsammlungen und Wörterbücher</span><br/>
                    <span class="speaker">Prof. Torsten Schrade, Digitale Akademie der Akademie der Wissenschaften und der Literatur Mainz, Hochschule Mainz</span><br/>
                    <span>NFDI-Konsortium der Archäologie/Kunstgeschichte/Musikwissenschaft “NFDI4Culture”</span><br/>
                    <span class="speaker">Prof. Dr. Dörte Schmidt, Gesellschaft für Musikforschung, Bernd-Alois-Zimmermann-Gesamtausgabe, Universität der Künste Berlin</span>
                </p>

                <p class="heading">
                    <span class="section-label">Pause (10.30 – 11.00 Uhr)</span>
                </p>

                <p class="heading" style="margin-bottom: 1rem;">
                    <span class="section-label">Sektion 4: Planung weiterer Schritte bei der Organisation eines Konsortiums und der Abstimmung mit weiteren Akteuren; Überlegungen zu den konkreten Aufgaben bei der Vorbereitung eines gemeinsamen Antrags</span><br/>
                    <span class="section-moderator">Moderation: Dr. Barbara Wiermann, Sächsische Landesbibliothek — Staats- und Universitätsbibliothek Dresden (SLUB) / Prof. Dr. Andreas Münzmay, Musikwissenschaftliches Seminar Detmold/Paderborn, ca. 90 Minuten</span>
                </p>

                <p class="heading">
                    <span class="section-label">Zusammenfassung</span><br/><span class="section-moderator">Dr. Ania López / Prof. Dr. Dörte Schmidt / Dipl. Wirt.-Inf. Daniel Röwenstrunk / Prof. Dr. Joachim Veit, ca. 30 Minuten</span>
                </p>
                <br/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'program',
        components: {
        }
    }
</script>

<style scoped>

    h1 {
        margin-bottom: 4ex;
        text-align: center;
    }

    .program {
        background-color: #ffffff;
        border-radius: 0 0 7px 7px;
        padding: 20px;
        color: #1b1e21;
        text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .2);
        z-index: 5;
        text-align: left;
    }

    .day-label {
        font-weight: bold;
        font-size: larger;
    }

    .section-label {
        font-style: italic;
    }

    .section-moderator {
        color: #666;
        padding-left: 0.5rem;
        font-size: smaller;
    }

    p.list span.speaker {
        color: #666;
        padding-left: 3ex;
        font-size: smaller;
        margin-bottom: 1ex;
        display: inline-block;
    }

    p.heading {
        margin-top: 2rem;
        margin-bottom: 0.25rem;
    }

    p.list span {
        padding-left: 2.5ex;
    }


</style>