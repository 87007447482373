<template>
    <div class="minutes">
        <h1>Zusammenfassendes Protokoll</h1>
        <p class="subtitle">13. Dezember 2018, 13–18:45 Uhr und 14. Dezember 2018, 9–13 Uhr <br/>im Heinz Nixdorf MuseumsForum (HNF) in Paderborn</p>

        <div class="row">
            <div class="col-12">


                <div class="level1">
                    <h2>Protokoll:</h2>
                    <ul>
                        <li>Kristin Herold M.A., Akademieprojekt "Beethovens Werkstatt", Virtueller Forschungsverbund Edirom, Universität Paderborn</li>
                        <li>Dr. Johannes Kepper, Akademieprojekt "Beethovens Werkstatt", Virtueller Forschungsverbund Edirom, Universität Paderborn</li>
                    </ul>
                    <h2>Verantwortliche Veranstalter:</h2>
                    <ul>
                        <li>Prof. Dr. Dörte Schmidt, Präsidentin der Gesellschaft für Musikforschung</li>
                        <li>Dr. Ania López, Projektleiterin der Landesinitiative NFDI der Digitalen Hochschule NRW</li>
                        <li>Dipl. Wirt.-Inf. Daniel Röwenstrunk, Zentrum Musik – Edition – Medien, Universität Paderborn</li>
                        <li>Prof. Dr. Joachim Veit, Virtueller Forschungsverbund Edirom, Universität Paderborn</li>
                    </ul>
                </div>
                <div class="level1">
                    <h2>Kurzzusammenfassung</h2>
                    <p>
                        Hauptziel der Veranstaltung war neben der Intensivierung der Kommunikation über musikwissenschaftliche Forschungsdaten <b>die konkrete Erhebung fachwissenschaftlicher Bedarfe der Musikwissenschaft</b> im Hinblick auf die künftige NFDI.</p>
                    <p>Zugleich diente der Workshop der weiteren Abstimmung im Hinblick auf die Bildung eines Konsortiums <b>"NFDI4Culture"</b> gemeinsam mit VertreterInnen der Kunstgeschichte und der Archäologie sowie dem Sondieren der Notwendigkeiten weiterer Kooperationen und Absprachen. Der Workshop deckte mit seinen ca. 80 TeilnehmerInnen aus unterschiedlichen Bereichen der Musikwissenschaft sowie anderer Fächer und einer regen Beteiligung aus Gedächtnis- und Kulturinstitutionen ein breites Anforderungsfeld ab.</p>
                    <p> Am Ende bestand ein breiter Konsens darüber, dass die während des Workshops aufgeworfenen Fragen und die Notwendigkeit, einen Überblick über bereits vorhandene Dienste und Angebote zu gewinnen, u.a. in einem gemeinsam zu erarbeitenden Dokument konkretisiert und Kontakte zu anderen geplanten Konsortien geknüpft werden sollten, um in den Geisteswissenschaften ein Geflecht eng miteinander kooperierender, sich gegenseitig ergänzender Konsortien zu etablieren. Weitere Workshops und Treffen in unterschiedlichsten Konstellationen auf dem Weg zu einer Ausarbeitung der NFDI-Pläne werden zeitnah folgen müssen, wenn eine Beteiligung in der ersten Antragsphase erreicht werden soll.
                    </p>
                </div>

                <div class="level1">
                    <h2>Grußworte und Einführung in die Thematik bzw. den Diskussionsstand</h2>
                    <ul>
                        <li>Prof. Dr. Birgitt Riegraf, Präsidentin der Universität Paderborn</li>
                        <li>Prof. Dr. Dr. Friederike Fless, Rat für Informationsinfrastrukturen</li>
                        <li>Dr. Ania López, Projektleiterin der Landesinitiative NFDI der Digitalen Hochschule NRW</li>
                        <li>Prof. Dr. Dörte Schmidt, Präsidentin der Gesellschaft für Musikforschung</li>
                        <li>Dipl. Wirt.-Inf. Daniel Röwenstrunk, Zentrum Musik – Edition – Medien, Universität Paderborn</li>
                    </ul>
                    <p>
                        Zu Beginn der durch die Präsidentin der Universität Paderborn, <b>Birgitt Riegraf</b>, eröffneten ersten Sektion unterstrich Dörte Schmidt die Veränderungen des Fachs durch die Digitalisierung und die sich damit bietenden Chancen. <b>Friedrike Fless</b> fasste für den Rat für Informationsinfrastrukturen die Entwicklung der NFDI-Initiative und die bisher deutlich gewordenen strukturellen Rahmenbedingungen zusammen, bei denen die Einbindung der NutzerInnen eine entscheidende Rolle spiele. Sie unterstrich die Offenheit des dynamisch zu verstehenden, mit Erweiterungen oder wechselnden Kooperationen rechnenden NFDI-Prozesses, der auf bestehenden Strukturen aufbaut und Konsens in einer Community voraussetzt. Trotz der zunächst nur zehnjährigen Vereinbarung ist eine dauerhaft Förderung Ziel des Prozesses.</p>
                    <p>Dass Forschungsdaten "nicht an Landesgrenzen halt machen", war für <b>Ania López</b> Ausgangspunkt für den Hinweis, dass die Landesinitiative NFDI der Digitalen Hochschule NRW selbstverständlich die Bemühungen unterschiedlicher Konsortien auch länderübergreifend unterstütze, wenn ausgehend von einer Veranstaltung wie dieser sprech- und handlungsfähige Communities in kontinuierlichem Austausch mit den Nutzern aufgebaut und Profile geschärft werden sollen. Sie verwies darauf, dass die neu gestartete <em>European Open Science Cloud</em> die Bund-Länder-Vereinbarungen zu NFDI mit großem Interesse verfolge. Offene Workshops sollten ein zentraler Bestandteil der konsortiums-internen Governance-Struktur sein und alle Arbeiten sollten in die Community rückgespiegelt werden.</p>
                    <p><b>Dörte Schmidt</b> stellte die aktuellen Planungen zu einem Konsortium zu audio-visuellen
                        Kulturgütern vor, die durch ihre medialen und materialen Bedingungen und die damit
                        zusammenhängenden methodischen Probleme sich oft wesentlich von dem unterscheiden,
                        was es in anderen Geisteswissenschaft, speziell den Textwissenschaften an (vielfach
                        bereits intensiv bearbeiteten) Fragen gibt. Kennzeichnend sei auch ein spezifisches
                        Verhältnis von materiellem und immateriellem Kulturgut: Bei der Digitalisierung von
                        Kulturgütern gehen die Daten ein besonderes Verhältnis zu ihren Vorlagen ein, so
                        dass sich die Daten nicht einfach in Primär- und Sekundärdaten unterteilen ließen.
                        Forschung an Kulturgütern verlangt deshalb auch eine Archivierung der
                        Forschungsdaten nach den Regeln der Kulturgutsicherung. Dagegen geben Rechenzentren
                        i.d.R. Garantien zur Datensicherung nur für Zeiträume von zehn Jahren. Vor diesem
                        Hintergrund kommen als Datenrepositorien auch für Forschungsdaten die mit diesen
                        Bedingungen befassten Gedächtnisinstitutionen eher in den Blick, als etwa
                        Repositorien, die vorwiegend naturwissenschaftliche Forschungsdaten sichern und den
                        entsprechenden Routinen unterliegen.<br /> Eine besondere Rolle spiele in diesem
                        Kulturgut-Bereich auch die Rechtefrage – insbesondere bei Noteneditionen existieren
                        teils sehr komplexe, weit über ähnliche Fragen bei Texten hinausgehende
                        Rechtsprobleme, die stellvertretend in einem "NFDI4Culture" thematisiert werden
                        sollten.<br /> Schmidt weist darauf hin, dass sich die FIDs
                        (Fachinformationsdienste), im Verbund mit der DDB und der DNB, als bestehende
                        Infrastrukturen anbieten, um den besonderen Bedürfnissen der kunst- und
                        kulturwissenschaftlichen Fächer Rechnung zu tragen. In den Reigen der Beteiligten
                        gehören aber ebenso die Akademien, an denen sehr viele langfristige
                        Forschungsprojekte angesiedelt sind, die teils auch eine bemerkenswerte
                        internationale Anbindung mit sich bringen. Institutionell seien neben der Stiftung
                        Preußischer Kulturbesitz, die einen erheblichen Datenschatz einbringe, auch Verbünde
                        wie DARIAH und CLARIN oder (auf fachspezifischer Ebene) ZenMEM zu erwähnen, die
                        bereits große Schritte in Richtung eines nachhaltigeren Datenmanagements gegangen
                        seien.<br /> Um diese Bemühungen zusammenzuführen, haben die beteiligten
                        Fachgesellschaften diesen Workshop initiiert, um eine "NFDI4Culture" zu skizzieren.
                        Es müsse darum gehen, <em>dauerhafte</em> Strukturen zu etablieren, die die
                        Schnittstellen zwischen den genannten Partnern pflegen und zentrale Dienste
                        bereitstellen. Dabei sollte keine Konkurrenz zu einem textzentrierten Konsortium
                        entstehen. Auch der Anschluss an andere Konsortien, etwa der Historiker, sei
                        wichtig, um eine Synergien bündelnde NFDI-Landschaft zu etablieren.</p>
                    <p><b>Daniel Röwenstrunk</b> unternahm im Hinblick auf die nachfolgenden Diskussionen den Versuch, verschiedene Ebenen von und Prozesse in Infrastrukturen zu unterscheiden:<br/> – Eine erste Ebene als <em>Service-Ebene</em> basiert auf Datenstandards (wie TEI, MEI, IIIF, PND Beacon oder dem vom Meta-Dienst correspSearch genutzten CMIF). Standards bilden die Grundlage für Verknüpfungen über Service-Grenzen hinaus, müssen aber zwingend erweiterbar sein, um zukünftige Forschungsfragen nicht zu behindern. Auf dieser Ebene gibt es <em>spezifische</em> und <em>generische</em> Dienste.<br/>
                        – Eine zweite Ebene ist <em>basal-technisch</em>; sie ist nicht fachspezifisch, sondern offeriert technische Basisdienste, auf denen die spezifischeren Ebenen aufsetzen, die aber leicht(er) "von außen" eingekauft werden können. Als Beispiele können etwa Docker oder GitHub gelten.<br/>
                        – Als dritte Dimension stellt Röwenstrunk eine <em>politische bzw. organisatorische Ebene</em> vor. Dabei geht es um Rechte- und Lizenzfragen, Vermittlungsziele, Forschungsförderung, Geschäftsmodelle, Kommunikation und Projektmanagement.<br/>
                        Hinsichtlich der genannten Prozesse verweist er auf die entsprechenden Überlegungen des <em>Data Research Lifecycle</em> von DARIAH. Dort wird unterschieden in Planung / Erstellung, Auswahl / Prozessierung, Ingest / Übernahme, Speicherung / Infrastruktur, Erhaltungsmaßnahmen sowie Zugriff und Nutzung. Diese Kriterien sollen eine individuelle Verortung ermöglichen.
                    </p>
                </div>

                <div class="level1">
                    <h2>Sektion 1: Impulsreferate und Diskussion zu Forschungsdaten aus unterschiedlichen Bereichen des musikbezogenen Kulturerbes in Bezug auf Forschungsgegenstände und Standards zu deren digitaler Erfassung</h2>
                    <ul class="moderators">
                        <li>Prof. Dr. Gudrun Oevel, Zentrum für Informations- und Medientechnologien (IMT), Universität Paderborn</li>
                    </ul>
                    <ul class="speakers">
                        <li>Dr. Heike Fricke, BMBF-Projekt TASTEN, Museum für Musikinstrumente Leipzig</li>
                        <li>Dr. Ricarda Kopal, Ethnologisches Museum Berlin</li>
                        <li>Prof. Dr. Aristotelis Hadjakos, Zentrum Musik- und Filminformatik, Hochschule für Musik Detmold</li>
                        <li>Peter Stadler M.A., Carl-Maria-von-Weber-Gesamtausgabe, Universität Paderborn</li>
                        <li>Dr. Ann Kersting-Meuleman, Sammlung Musik und Theater der Universitätsbibliothek Frankfurt/Main, IAML Deutschland</li>
                    </ul>
                    <p>Ziel dieser Sektion war das Aufzeigen der Vielfalt der im Bereich der Musikwissenschaft auftretenden Daten (der Datenbegriff selbst war nicht Gegenstand der Diskussion, sondern wurde hier in einem sehr breiten Sinn benutzt) und das Verdeutlichen bereits genutzter Standards bzw. der Probleme durch fehlende Standardisierung. Selbstverständlich konnten dabei aus Zeitgründen stellvertretend nur einige ausgewählte Bereiche zu Worte kommen.</p>
                    <p>Den Bereich <b>Musikinstrumente</b> stellte <b>Heike Fricke</b> mit dem am Musikinstrumentenmuseum in Leipzig beheimateten Projekt "TASTEN" vor. Dort arbeiten Musikwissenschaftler gemeinsam mit Informatikern, um Datenstandards zur Beschreibung von Instrumenten zu entwickeln. Dabei geht es u.a. auch um CT-Daten, die einen Augmented-Reality-Blick in das Innenleben von Instrumenten erlauben. Geodaten, Personendaten und Zeitdimensionen dienen der Darstellung von Netzwerken. Diese vielfältigen Datenarten werden sowohl maschinen- als auch menschenlesbar angelegt und u.a. an Normdaten (VIAF etc.) angeschlossen, die z.B. auch eine Anbindung an Crowdsourcing-Plattformen (Wikimedia u.a.) erlauben. Die behandelten Informationen vernetzen unterschiedlichste Disziplinen, u.a. auch Botanik (für Materialien). Gleichzeitig ist ein Austausch mit anderen Formaten (u.a. MIDI) zentral. Erprobt werden u.a. Distant Reading-Ansätze. Für eine Nachnutzung der Daten ist das Projekt allerdings noch in einer zu frühen Phase.</p>
                    <p>Die <b>Musikethnologie </b> war durch <b>Ricarda Kopal</b> vertreten, die die Bestände des Ethnologischen Museums Berlin vorstellte. Diese umfassen Tonträger (von Wachswalzen bis zu digitalen Daten), (vorwiegend nicht-europäische) Musikinstrumente, darauf bezogene Schriftgüter und allgemeine Literatur, Medientechnik, Fotografien und auch audiovisuelle Materialien. Inhaltlich geht es um Musik als soziale Praxis verschiedener Kulturen. Die Sammlung selbst kann als (teils analoge) Forschungsdaten-Sammlung aufgefasst werden, die in der wissenschaftlichen Auseinandersetzung neue Forschungsdaten produziert. Das Museum arbeitet intern bereichsspezifisch mit verschiedenen Datenstandards (noch nicht mit CIDOC-CRM) und Datenbanksystemen, wobei sich der Austausch mit anderen, teils proprietären Formaten oft als problematisch erweist. Die Forschungsdaten erlauben unterschiedliche Zugänge, etwa aus Sicht der Vergleichenden Musikwissenschaft, der Wissenschaftsgeschichte, der Ethnologie oder Linguistik, aber auch als Memorabilia. Eine Rückbindung an externe Forschungen ist dabei bislang schwierig und nur durch eine direkte Einbindung in die eigenen Bestände möglich. Gerade die Frage nach Multiperspektivität ist aus musikethnologischer Sicht aber überaus zentral.
                    </p>
                    <p>Das von <b>Aristotelis Hadjakos</b> vorgestellte Feld des <b>Music Information Retrieval (MIR)</b> befasst sich mit der Extraktion, Analyse und Nutzung von aus Musik generierten Daten, basierend einerseits auf Audioquellen, andererseits auf symbolischen Daten (Notentexten). MIR sei ein sehr interdisziplinäres Feld, in dem etwa Suche nach Melodiezitaten in größeren Korpora, Score-Following, Vorschläge für Playlists, das Generieren von Aufnahmen aus Notendaten und vieles mehr eine Rolle spielen. Genutzte Formate für Audio sind WAV oder MP3, für symbolische Daten werden MIDI-Daten genutzt, für Partiturdaten MusicXML und MEI eingesetzt, teils auch einfache Bilddaten (PDF bzw. JPG). Eigene generische Datenformate habe die Community hingegen kaum entwickelt. Stattdessen gebe es oft sehr spezifische, nur jeweils projektintern genutzte Datenformate, die nicht zur Nachnutzung bestimmt seien. Eine Neuerung sei das <a href="https://github.com/marl/jams">JAMS-Format</a>, das versucht, übergreifende Strukturen für Musikannotationen zu bilden und damit Anknüpfungspunkte zur Musikedition bzw. anderen Forschungsbereichen erlaube. Wünschenswert seien Schnittstellen, um Daten aus anderen Projekten, Museen etc. in Standardformaten "abgreifen" und für eigene Forschungen nutzen zu können, wobei es ideal sei, wenn für solche Standardformate bereits Parser vorlägen. Es geht also in erster Linie darum, ein ganzes Framework an kompatibler Software zu erarbeiten. Rechtliche Probleme etwa bzgl. Copyright-geschützter Daten fielen zwar nicht ins Gewicht, wenn es nur um die Entwicklung von Algorithmen gehe, sie schränkten allerdings ggf. mögliche Forschungsfragen ein – was nicht zur Verfügung stehe, könne auch nicht erforscht werden.
                    </p>
                    <p>Forschungsgegenstand von <b>(Musik-)Editionen</b>sind laut <b>Peter Stadler</b> sowohl Textträger (in ihrer Materialität) als auch aus ihnen ableitbare Texte, die von literarischen Werken bis zu Notentexten reichen. Edition erfordere stets eine kritische Reflektion seitens der Herausgeber*innen und sei per se Grundlagenforschung – sie erlaube es nachfolgenden Forscher*innen auf einer soliden Grundlage aufzusetzen, anstatt selbst Quellenforschung betreiben zu müssen. Digitale Edition müsse standardbasiert sein (TEI, MEI, Epidoc, etc.), um Anschlussfähigkeit herzustellen. Durch die Nutzung von Normdaten könne über Identifikatoren auf entsprechende Einträge Bezug genommen werden. Bilddateien als Digitalisierungen der Textzeugen erhöhen die Transparenz der Edition und werden teils weiter ausgezeichnet, auf Zeilen- (Escher-Edition) oder gar Zeichen-Ebene (Beethovens Werkstatt). Als künftig relevanter Bildstandard etabliert sich IIIF. Während die Sicherstellung der Nachnutzbarkeit eine Aufgabe der Editionen selbst sei, gehe die inhaltliche Forschung auf diesen Daten schon allein aus Kapazitätsgründen darüber hinaus. Wichtig sei daher die Anbindung an eine Vielzahl relevanter Datenformate, um diese Nachnutzung zu ermöglichen. Zahlreiche generischen Werkzeuge seien bislang für den Textbereich durch die sehr aktive Community entwickelt worden. Für den Bereich Musik scheinen die Herausforderungen aufgrund des Materials erheblich größer, bei einer gleichzeitig deutlich kleineren Community. Entsprechend fehlen bisher tragfähige Strukturen, um diese Dienste ebenso sicherzustellen wie eine notwendige Kuration der Daten nach Projektende. Sehr wichtig sei auch die Frage, wohin sich neue (wie auch bestehende) Projekte wenden können, um die Langzeitarchivierung der jeweiligen Daten sicherzustellen. Entsprechende Strukturen seien bislang nicht vorhanden.</p>
                    <p>Abschließend in dieser Sektion befasst sich <b>Ann Kersting-Meulemann</b> mit der Rolle <b>musikbegzogener Metadaten</b>, die für eine eindeutige Identifikation von Datensätzen wichtig seien. Mit dem seit 1995 weit verbreiteten <a href="https://de.wikipedia.org/wiki/Dublin_Core">Dublin Core</a>-Standard (DC) könne zwischen formalen, urheberbezogenen, inhaltlichen und rechtlichen Metadaten unterschieden werden. Im <em>Telemann digital</em>-Projekt der UB Frankfurt z.B. würden diese DC-Daten hinterlegt und in Bezug zu Normdaten (u.a. GND und RISM) gesetzt. Volltexte etwa von Kantaten-Texten werden als TEI verlinkt, auch Geodaten einbezogen, Wasserzeichen als Bilddateien eingebunden. Volltexte würden im bibliothekarischen Bereich i.d.R. per OCR erschlossen. In der Diskussion werden im Hinblick auf Personendaten die Verlinkung auf Wikipedia und die nicht freie Zugänglichkeit von Personendaten aus MGG Online problematisiert.</p>
                    <p>In der <b>Abschlussdiskussion zu Sektion 1</b> spricht Andreas Münzmay u.a. die Frage der Nachnutzbarkeit der in den Bibliotheken gesammelten Daten für die Forschung angesprochen. Deutlich wird dabei, dass für Bibliotheken zunächst die grundsätzliche Verfügbarkeit der Datensätze in digitaler Form sowie deren Langzeitarchivierung Vorrang habe (was im vorhandenen Ökosystem meist über METS / MODS geschehe). Laut Joachim Veit fehlt allerdings eine wirkliche Anbindung an Forschungsprojekte, deren Ergebnisse bislang nicht wieder in die Bibliothekskataloge zurückgeführt werden. Kersting-Meuleman verweist darauf, dass nicht nur zum wissenschaftlichen Bereich, sondern auch zu den Archiven aufgrund anderer genutzter Formate bisher keine zufriedenstellende Anbindung besteht. Gudrun Oevel betont, dass alle genannten Bereiche unterschiedliche Zielsetzungen haben und daher schon konzeptionell "aneinander vorbei redeten". Für Klaus Keil escheint es schwierig, übergreifende Standards zu entwickeln, die die genannte Breite der Anforderungen an musikalische Quellen abdecken. Dominik Leipold ergänzt, dass ein Mapping verschiedener Formate aus den verschiedenen Bereichen oft aussichtsreicher sei, als es auf den ersten Blick erscheine. Keil merkt an, dass Standards und vor allem deren Dokumentation weiterhin wichtig seien, da sich solche Brücken nicht allein durch Software abbilden lassen. Laut Dörte Schmidt könne RISM bei diesen Versuchen einer Verknüpfung, wie sich bereits jetzt zeige. eine zentrale Rolle spielen.</p>
                </div>


                <div class="level1">
                    <h2>Sektion 2: Podiumsdiskussion zu Infrastrukturen: Bedarfe, existierende Angebote, Herausforderungen, rechtliche Rahmenbedingungen, Ebenen von Infrastrukturen und Rollen und Verantwortung einzelner Institutionen im Digitalisierungs-, Forschungs- und Kurationsprozess (Lebenszyklen) von Daten</h2>
                    <ul class="moderators">
                        <li>Dipl. Wirt.-Inf. Daniel Röwenstrunk</li>
                        <li>Prof. Dr. Joachim Veit</li>
                    </ul>
                    <ul class="speakers">
                        <li>Prof. Dr. Christian Czychowski, Rechtsanwalt / Fachanwalt für IT, Urheber- u. Medienrecht</li>
                        <li>Peter Gietz M.A., DAASI International, Vorstand DHd, DARIAH-DE</li>
                        <li>Dr. Reiner Nägele, Musikabteilung Bayerische Staatsbibliothek und FID Musik</li>
                        <li>Prof. Dr. Gudrun Oevel, Zentrum für Informations- und Medientechnologien (IMT), Universität Paderborn</li>
                        <li>Johannes Sauter M.A., Deutsches Museum Digital, KultSam</li>
                        <li>Prof. Dr. Dörte Schmidt, Gesellschaft für Musikforschung, Bernd Alois Zimmermann-Gesamtausgabe, Universität der Künste Berlin</li>
                        <li>Prof. Torsten Schrade, Digitale Akademie der Akademie der Wissenschaften und der Literatur Mainz, Hochschule Mainz</li>
                        <li>Prof. Dr. Nicole Schwindt, Staatliche Hochschule für Musik Trossingen, RISM</li>					</ul>
                    <p>
                        <b>Christian Czychowski</b> rät, bei der Konzeption des Konsortiums zwar die <b>juristischen Fragestellungen</b> im Blick zu behalten, aber vor allem auch urheberrechtliche und datenschutzrechtliche Fragen bzw. Persönlichkeitsrechte und Namensrechte der behandelten Personen und Gegenstände zu berücksichtigen. Man solle nicht aufgrund etwaiger rechtlicher Probleme Forschung unterlassen, sondern stattdessen über politische Prozesse Änderungen an den rechtlichen Rahmenbedingungen anregen. Auch ließen sich viele rechtliche Probleme durch juristische Konsultation klären, wenn man den Dialog suche. Reinhard Keil merkt an, Rechte seien hinsichtlich Zitationen vor allem audiovisueller Medien sehr restriktiv, so dass derartige Daten für die Forschung potentiell problematisch seien. Czychowski verweist darauf, dass durch höchstrichterliche Urteile die Kunst- und Wissenschaftsfreiheit aktuell sehr hoch eingeschätzt werde und dass nicht die Nutzung/Zitation problematisch sei, sondern bereits davor die Aufnahme solcher Gegenstände in Datenbanken ein Problem darstelle. Auf die Frage von Nicole Schwindt, wem die Daten gehörten, die in Projekten erstellt werden, erwidert Czychowski, dass in der Juristerei nicht von "Daten" gesprochen werde, sondern von rechtsbehafteten Gegenständen. Konzeptionell gebe es keine (Eigentums-)Rechte an Daten, sondern nur Urheberrechte an den Inhalten.<br/>
                        Schmidt fragt nach Nutzungsrechten, die im digitalen Bereich durch beschränkte Zugänge umgesetzt werden, wobei der Anbieter dennoch hafte und damit in Rechtsprobleme geraten könne. Czychowski verneint dies als Problem – für jede öffentliche Aktivität von Museen, Archiven etc. seien diese schon jetzt haftbar, wodurch man sich aber keinesfalls abschrecken lassen dürfe. Stattdessen seien geeignete Nutzungsbedingungen das Mittel der Wahl, um diese Problematik auszuräumen. Torsten Schrade ergänzt, dass die Absicherung solcher Zugänge hohe technische Anforderungen mit sich bringe, die laut Czychowski allerdings auch bei großen Unternehmen nicht immer optimal umgesetzt würden. Es sei jedoch nicht nötig, nach dem Stand der Wissenschaft <em>und</em> Technik zu agieren, der <em>Stand der Technik</em> reiche völlig aus, um rechtliche Risiken zu minimieren. Reiner Nägele fügt an, dass die Gedächtnisinstitutionen all dies mit Dokumentenservern etc. bereits umsetzen, und dies auch gerne in ein anzudenkendes NFDI-Konsortium einbringen. Schmidt resümiert, dass eine weitere fundierte Rechtsberatung für den NFDI-Prozess außerordentlich wichtig sei und dankt Czychowski, dass er für dieses Eingangsstatment zur Verfügung stand.</p>

                    <div class="level2">
                        <h3>Kurze Statements der Podiumsteilnehmer*innen</h3>
                        <p>
                            <b>Peter Gietz</b> erwähnt in seinem Eingangsstatement die lange Tradition der Förderung von DH durch das BMBF (TextGrid, DARIAH-DE,  eHumanities-Zentren). Allerdings wünsche er sich mutigere Ansätze etwa zur Erprobung von gGmbH-Modellen. Der NFDI-Prozess scheine hier ein richtiger Schritt in Richtung nachhaltiger Infrastrukturen zu sein, auch wenn sich die Dauerhaftigkeit des Ansatzes noch erweisen müsse. Es gelte nun, die bestehenden Infrastrukturen (Datenrepositorien, Datenzentren, FIDs, Bibliotheken etc.) zu vernetzen und eine sinnvolle Gesamtstruktur zu etablieren. Dabei sollten die generischen Dienste, die im Rahmen der BMBF-Förderung (z.B. DARIAH-AAI) entwickelt wurden und sich im CLARIAH-DE-Konsortium zusammenfinden wollen, auch von einem "NFDI4Culture" nachgenutzt werden.</p>
                        <p>
                            <b>Reiner Nägele</b> betonte, dass Gedächtnisinstitutionen keinen
                            fachwissenschaftlichen Auftrag haben und dementsprechend immer nur generische
                            Dienste bereitstellen könnten. Er stellt die verschiedenen Angebote und Dienste
                            an der BSB München (wie auch der SLUB Dresden) vor, die in vielen Bereichen
                            <em>state of the art</em> seien. Auch gebe es ab 2019 ein DFG-Projekt <a
                                href="https://www.bsb-muenchen.de/ueber-uns/projekte/forschungsdatendienst-fuer-die-ost-ostmittel-und-suedosteuropaforschung-ostdata/"
                        >OstData</a> (Forschungsdatendienst für die Ost-, Ostmittel- und
                            Südosteuropaforschung), das (außerhalb der NFDI-Förderung) infrastrukturelle
                            Grundlagen zur digitalen Speicherung, Veröffentlichung, Langzeitarchivierung und
                            Suche von Forschungsdaten erarbeiten solle. </p>
                        <p>
                            <b>Gudrun Oevel</b> stellte heraus, dass Rechenzentren und Universitätsbibliotheken in erster Linie optimale Arbeitsbedingungen für ihre Wissenschaftler*innen bereitzustellen haben. Dabei gehe es darum, lokale Services mit externen Diensten möglichst redundanzfrei zu verknüpfen. Damit handele es sich in erster Linie um eine (lokale) Vermittlerrolle.
                        </p>
                        <p>
                            <b>Johannes Sauter</b> stellte klar, dass <a href="https://www.leibniz-gemeinschaft.de/infrastrukturen/leibniz-roadmap/kultsam/">KultSam</a> nicht in Richtung NFDI-Antrag denke, sondern andere Förderwege anstrebe. Es gehe um eine digitale Forschungsplattform für Forschung, Lehre und öffentliche Vermittlung. Hinter KultSam stehen u.a. vier Leibniz-Forschungsmuseen. Um die auch hier relevante Frage nach der Governance anzugehen, arbeite man mit einer externen Beratungsgesellschaft zusammen. Ziel sei langfristig die digitale Bereitstellung und spätere Beforschung der digitalisierten Objekte. Dabei gehe es um eine Erweiterung der genutzten CIDOC-CRM-Daten, etwa um 3D-Daten. Auch wenn man außerhalb des NFDI stehe, sei Kooperation dennoch überaus wichtig, um eine wechselseitige Anschlussfähigkeit herzustellen.
                        </p>
                        <p>
                            <b>Dörte Schmidt</b> fragte, in welcher Weise eine vollständige Community
                            eingebunden werden könne. Es gebe niemanden mehr, der keine digitalen Daten
                            erzeuge. Wichtig seien daher attraktive Angebote für die Nutzer, um Zugänge zu
                            diesen Sicherungs-Strukturen wie zu den darin verfügbaren Materialen zu
                            schaffen. Dies erfordere eine gute Angebotsstruktur, eine gute Beratung, und
                            aber auch eine Wahlfreiheit der Nutzer*innen, die so zu souveränen Akteuren
                            werden und auch die Hoheit über ihre Daten behalten. Weiterhin verändere sich
                            die Rolle von Fachgesellschaften. Diese stellten eine Sprechfähigkeit gegenüber
                            politischen Strukturen her, da sie als legitimierte Vertreter ihre Communities
                            vertreten können. Entsprechend müssen Fachgesellschaften sicherstellen, dass sie
                            die Breite ihrer Disziplinen abbilden; die vorhandenen Beziehungen zu
                            Kulturverbänden seien im NFDI-Kontext überaus hilfreich. Wichtig sei auch der
                            kontinuierliche Austausch, um im Dialog neue Fragestellungen zu entdecken. </p>
                        <p><b>Torsten Schrade</b> führte aus, dass DH zunächst generische formale Modelle entwerfe, die dann technisch implementiert würden. Dies betreffe an der Mainzer Akademie verschiedene Projekte, die in unterschiedlichsten Kontexten arbeiten. Ein Vorschlag für ein solches generisches Modell betreffe z.B. Werkverzeichnisse, die sich gut als Cluster kapseln lassen. Die Mainzer Akademie sehe sich daher als mögliche Schnittstelle bzw. Bindeglied zwischen den beiden geplanten Konsortien "NFDI4Culture" und "NFDI4Text", die nicht als Konkurrenz zu verstehen seien, sondern sich vielmehr ergänzen. Weiterhin müsse die Ausbildung von Digital Humanists sehr viel stärker in den Blick genommen werden.
                        </p>
                        <p>
                            <b>Nicole Schwindt</b> stellte abschließend <a href="http://rism.info">RISM</a>
                            vor und grenzte es von den anderen Projekten (RIdIM, RILM) ab. RISM erfasst auf
                            nationaler Ebene musikalische Quellen und verwaltet die Datensammlung auf
                            internationaler Ebene. Es habe eine Pilotfunktion, die im NFDI auszubauen sei.
                            Zusätzlich zu den in großem Umfang in elektronischer Form vorhandenen Daten
                            müssten nicht oder nicht digital erfasste Quellen bearbeitet werden, um
                            zukunftsfähig zu sein.. NFDI könnte auch ein Auffangbecken sein, um die
                            partiellen Parallelentwicklungen von <em>MUSCAT</em> (der von der
                            Zentralredaktion betreuten Katalogisierungssoftware zur Datenerfassung mit
                            eigener Bereitstellungskomponente) und dem <em>OPAC</em> (dem durch die BSB
                            München betreuten Benutzerkatalog zur Datenrecherche) zu harmonisieren. Es
                            bestehe ein großes Interesse an der Einbindung in eine Struktur, die auch eine
                            Langzeitperspektive  über die jetzigen Förderperioden hinaus bereitstelle. RISM
                            habe den Anspruch, das zentrale Nachweisinstrument für musikalische Quellen zu
                            sein. Folgerichtig hat man damit begonnen, Schnittstellen zu Digitalisaten zu
                            integrieren, um auch diese zentral zugänglich machen zu können. Eine aktuelle
                            Aufgabe sei der Ausbau der Anbindung an Normdaten, speziell von Werken. RISM sei
                            durch das Erreichte bereits ein internationales Aushängeschild, und sollte
                            aufgrund seines Potentials in eine NFDI aufgenommen werden, aber eigenständig
                            sichtbar bleiben. </p>
                        <h3>Fortsetzung der Podiumsdiskussion</h3>
                        <p>Oevel fragt, was der eigentliche Mehrwert einer NFDI aller anwesenden Partner sei. Schrade verweist auf die sich durch Verküpfung der vorhandenen Forschungsdaten ergebenden Forschungsperspektiven. Langzeitarchivierung und rechtliche Fragestellungen ließen sich gut zentralisieren, um so Mehrfachstrukturen zu vermeiden. Auch Nägele führt an, dass teils von den gleichen Geldgebern mehrfach ähnliche Projekte in dieser Richtung gefördert würden, wodurch eine nicht zielführende Konkurrenzsituation entstehe. Fricke betont die Wichtigkeit der disziplinübergreifenden Vernetzung, da in ihrem derzeitigen Projekt u.a. Physik, Materialwissenschaft und Musikinstrumentenkunde eng miteinander verzahnt sind. Schmidt ergänzt, dass Defragmentierung auch zu einer größeren Effizienz führe, da so nicht immer neue Räder erfunden werden müssten. Nur so könnten die sonst oft nicht zu erreichenden Ziele von Langzeitverfügbarkeit erreicht werden, und gleichzeitig Ressourcen für eine stärker multiperspektivisch gedachte Auseinandersetzung (bibliothekarisch wie auch fachwissenschaftlich) mit den Inhalten frei werden.</p>
                        <p>Kathrin Kirsch hinterfragt, ob die Überlappung mit den Archäologen und Kunsthistorikern tatsächlich größer sei als mit den Literaturwissenschaftlern. Man müsse nach außen sehr gut kommunizieren, aus welchen Überlegungen heraus diese Kombination angestrebt werde, und auch wie die Schnittstellen zu anderen Konsortien geplant seien. Röwenstrunk und Schmidt führen aus, dass dieser Austausch zentral sei, dass es im geplanten NFDI4Culture aber gerade um die nicht-textuellen Fragestellungen gehe – die eben nicht in anderen Konsortien thematisiert würden.</p>
                        <p>Stadler führt aus, dass die Digitalisierung im GLAM-Sektor zu völlig neuen Partnerschaften führe. Während die Schnittstellen auf technischer Ebene absehbar seien, mangele es vor allem an personellen Schnittstellen, d.h. an Ansprechpartnern, die entsprechend ausgelegte Projekte hinsichtlich dieser Schnittstellen beraten könnten. Schmidt betont, dass Projekte nicht nur Daten hinterlassen, sondern auch Software. Diese brauche aber für eine längerfristige Nutzung in besonderem Maße Menschen mit entsprechenden Entwicklungs- und Betreuungskompetenzen. Besonders durch die fachspezifischen fragmentierten Repositorien ergebe sich also ein großer Bedarf an "menschlichen Schnittstellen".</p>
                        <p>Veit fragt Gietz und Oevel, in welchem Verhältnis fachspezifische und generische Dienste in bestehenden Datenzentren stehen. Gietz führt aus, dass es hier ein breites Spektrum gebe, und die vorhandene Vielzahl (fach)spezifischer Dienste und Repositorien auf einem Kern generischer Dienste wie Storage aufsetze. Wichtig sei, dass neue Dienste immer wieder auf bestehende Daten zugreifen könnten. Dieser Kuratierungsaufwand sei erheblich, aber zwingend notwendig zur Langzeitarchivierung und -verfügbarkeit. Oevel ergänzt, dass Zentren natürlich Unterstützungsleistungen bieten könnten, diese sich aber nicht in Virtualisierungslösungen etc. erschöpfen dürften. Wichtig sei es daher, die Ebenen klar zu trennen und auch im Blick zu behalten, wie langfristig mit diesen Daten umzugehen sei.</p>
                        <p>Barbara Alge regt an, über das Konsortium Beratungsleistungen anzubieten, um gute wissenschaftliche Praxis im Umgang mit digitalen Daten sicherzustellen. Georg Schelbert ergänzt, dass es sich nicht um fachspezifische Fragestellungen handele, sondern eher um methodische Erwägungen. Alge regt eine Zusammenarbeit der Musikethnologie mit der (allgemeinen) Ethnologie an, da diese in den hier angesprochenen Fragen bereits sehr weit sei. Schmidt verdeutlicht, dass es beim Zuschnitt eines Konsortiums nicht um den Ausschluss anderer Disziplinen gehe, sondern um die Identifikation gleicher Fragestellungen. Schrade führt aus, dass Generik aus dem Tun entstehe – man beginne an einer konkreten Stelle, und im Laufe der Arbeit entwickele sich daraus dann die Gemeinsamkeit, die im Vorfeld mitunter schlecht abschätzbar sei.</p>
                    </div>
                    <div class="level1">
                        <h2>Sektion 3: Konsortienbildung und Kooperation mit anderen Konsortien: Blick der möglichen Konsortiumspartner auf die bisherige Diskussion und Identifikation fächerübergreifender Gemeinsamkeiten zur Schärfung eines Konsortiumprofils; Spezifika eines denkbaren Konsortiums “NFDI4Culture” und Schnittstellen zu anderen Konsortien</h2>
                        <ul class="moderators">
                            <li>Dipl. Wirt.-Inf. Daniel Röwenstrunk</li>
                        </ul>
                        <ul class="speakers">
                            <li>Prof. Dr. Reinhard Förtsch, Deutsches Archäologisches Institut, Berlin</li>
                            <li>Dr. Georg Schelbert, Institut für Kunst- und Bildgeschichte, Humboldt-Universität zu Berlin</li>
                            <li>Dr. Martin Dröge, Neueste Geschichte, Universität Paderborn</li>
                            <li>Peter Gietz M.A., DAASI International, Vorstand DHd, DARIAH-DE</li>
                            <li>Prof. Torsten Schrade, Digitale Akademie der Akademie der Wissenschaften und der Literatur Mainz, Hochschule Mainz</li>
                            <li>Prof. Dr. Dörte Schmidt, Gesellschaft für Musikforschung, Bernd Alois Zimmermann-Gesamtausgabe, Universität der Künste Berlin</li>
                        </ul>
                        <div class="level2">
                            <h3>Archäologie</h3>
                            <p><b>Reinhard Förtsch</b> weist zu Beginn darauf hin, dass sich Archäologien disziplinär in etwa 10 Fächer aufteilen, die institutionell sowohl an Hochschulen angebunden als auch in Form von Landesdenkmalpflegen organisiert sind, hinzu kommt das Deutsche Archäologische Institut (DAI). Zudem gibt es mehrere Berufsverbände für Archäologen. Das DAI hat aktuell ~300 Forschungsprojekte und ~70 Grabungen, deren umfangreiche Forschungsdaten in einem Cloud-basierten System vorgehalten werden. Dabei werden u.a. auch Neuronale Netze zur automatischen Klassifikation genutzt. Förtsch betont, dass Materialität bzw. Objekte als "Sprache eigenen Rechts" verstanden werden müssen, die gegenüber Textquellen keineswegs nachrangig seien. Dabei gebe es deutliche Anküpfungsmöglichkeiten an andere Disziplinen, etwa die Kunstgeschichte. Für die NFDI empfiehlt er, durch klar prüfbare Kriterien bewährte Services für die Bedürfnisse einer definierten Community weiter zu stützen statt in weiteren Top-Down-Finanzierungen Infrastrukturen ohne nachhaltige Relevanz für die Praxis zu entwickeln. López fragt, inwiefern Förtsch Anknüpfungspunkte zu den gestern vorgestellten Projekten sehe. Förtsch sieht "Musikarchäologie" zwar als Nischengebiet, sieht aber gute Verknüpfungsmöglichkeiten z.B. im Bereich des Rechtemanagements, im Bereich des CIDOC-CRM oder anderer Ontologien. Ob dies jedoch ausreiche, um ein gemeinsames NFDI-Konsortium zu rechtfertigen, sei noch unklar. Auch Archivierung sei ein recht unspezifisches Kriterium. Diese unspezifischen Querschnittsthemen seien allerdings leichter zu finden als inhaltlich-methodische Übereinstimmungen.<br/>
                                Reinhard Keil zweifelt, ob es überhaupt möglich sei, Dienste "bottom-up" am Grünen Tisch zu entwerfen. Förtsch verweist darauf, dass es der NFDI ja gerade um die Stärkung bestehender Services gehe, nicht um die Entwicklung neuer Dinge. Auf die Frage von Ortrun Brand, welche förderfähigen Dienste es im Bereich der Archäologie aus Förtsch’s Sicht gebe, nennt dieser <a href="https://www.ianus-fdz.de">IANUS</a>, das zwar am DAI entwickelt wurde, aus verschiedenen Gründen aber nicht dort betrieben werde. Denkbar sei nun, IANUS innerhalb der NFDI fortzuschreiben.</p>
                        </div>

                        <div class="level2">
                            <h3>Kunstgeschichte</h3>
                            <p>Auf den bereits seit mehreren Monaten geführten Dialog zwischen den Fächern verweist <b>Georg
                                Schelbert</b> zu Beginn seiner Ausführungen, die er weniger auf Services
                                als auf den Umgang mit Daten ausrichten möchte, auch wenn Services
                                selbstverständlich Grundlage der Beteiligung am NFDI sein werden.
                                Forschungsdaten in der Kunstgeschichte seien zunächst einmal digitalisierte
                                Artefakte, aber auch (Text-)Quellen, Forschungsliteratur sowie
                                Referenzdaten, Normdaten, Klassifikationen etc. Es bleibe zu definieren, ob
                                Forschungsdaten <em>durch Forschung erzeugte Daten</em> meine oder
                                <em>potentiell beforschbare Daten</em>. Schelbert plädiert hier für
                                einen möglichst weiten Begriff.<br /> Die Kunstgeschichte produziere eine
                                enorme Anzahl sehr heterogener Digitalisate, vorwiegend als Bild, zunehmend
                                aber auch als 3D. In diesem Bereich gebe es aktuell zahlreiche Ansatzpunkte
                                für Standardbildung, etwa im Bereich der Dokumentation. Durch die zunehmende
                                Berücksichtigung der "Medienschichten" multiplizierten sich die Daten, da
                                man etwa auch ältere Fotografien von Kunstwerken digitalisiere. In der
                                Kunstgeschichte ist der Umgang mit Dateien gespalten zwischen akademischer
                                Forschung (Universitäten, Forschungsinstitute sowie zugehörige
                                Infrastrukturen wie Bibliotheken und Bildarchive) und den
                                Bewahreinrichtungen der Werke (Museen, Denkmalpflege, die im weitesten Sinn
                                der Kulturverwaltung zugehören). NFDI könne hier eine Brückenrolle
                                einnehmen. Er führt eine Reihe von Projekten und Initiativen im Bereich der
                                Kunstgeschichte auf, die exemplarisch für den Umgang mit Forschungsdaten
                                stehen: <a href="https://www.startext.de/produkte/hida">Hida</a> / Midas, <a href="https://www.cidoc-crm.org/">CidocCRM</a>, <a href="https://www.prometheus-bildarchiv.de/">prometheus</a>, <a href="wiss-ki.eu/">WissKI</a>, FIDs
                                (<a href="https://www.arthistoricum.net/">arthistoricum.net</a>), <a href="https://iiif.io/">IIIF</a>, <a href="https://wiki.dnb.de/pages/viewpage.action?pageId=134055796">GND4C</a> (Erstellung von Werknormdaten), <a href="www.iconclass.org/">IconClass</a>.
                                Um aus der Projektlogik herauszukommen, habe man 2011 den <em><a href="digitale-kunstgeschichte.de/">Arbeitskreis
                                    Digitale Kunstgeschichte</a></em> initiiert, der nun eng mit dem Verband
                                deutscher Kunsthistoriker zusammenarbeitet. Nicole Strohmann fragt, ob es
                                Standards zur Erfassung von Metadaten gebe, die auch eine Anbindung an Texte
                                vorsehen, insbesondere hinsichtlich der Kombination von Text und Bild, die
                                auch für die Musikwissenschaft relevant sei. Schelbert entgegnet, dass sich
                                hier durch die Verbindung von TEI, CidocCRM und anderen Standards Lösungen
                                finden ließen, dass dies aber grundsätzlich ein Desiderat sei, welches im
                                Rahmen eines NFDI-Konsortiums thematisiert werden sollte.</p>
                        </div>

                        <div class="level2">
                            <h3>Beobachtungen zur Konsortiumsbildung im Fach Geschichte</h3>
                            <p><b>Martin Dröge</b> stellt die AG "Digitale Geschichtswissenschaft" vor, die u.a. auch einen
                                eigenen <a href="http://digigw.hypotheses.org">Blog</a> betreibt. Im Sommer
                                2018 fand ein NFDI-Workshop "Forschungsdaten der Geschichtswissenschaft" der
                                Historiker in Paderborn statt, der die Relevanz des Themas für das Fach
                                verdeutlicht habe. Auf dem <a
                                        href="https://www.hsozkult.de/conferencereport/id/tagungsberichte-7988"
                                >Historikertag</a> im September 2018 wurde dann ein entsprechender
                                Workshop mit Podiumsdiskussion zum Thema angeboten. Dabei ging es sowohl um
                                die Autorschaft an Daten, Qualitätssicherung und Forschungsdatenmanagement,
                                jedoch noch ohne eine konkrete Auseinandersetzung mit dem NFDI-Prozess. Das
                                Positionspapier des VHD zur NFDI vom November 2017, in dem auf die Rolle
                                historischer Verlaufsdaten und die Abbildbarkeit von Ungenauigkeiten und
                                Datenlücken hingewiesen wurde, signalisiere Offenheit für die NFDI – die
                                Geschichtswissenschaft sei an einer Beteiligung interessiert und diskutiere
                                mit verschiedenen Partnern über mögliche Konsortien.<br /> Der VHD strebe
                                kein zentralisiertes Datenzentrum an, das für alle Epochen und Teilbereiche
                                der Geschichtswissenschaft gleichermaßen zuständig sei. Stattdessen plädiere
                                er für eine verteilte und vernetzte Struktur spezialisierter Datenzentren,
                                die allerdings koordiniert werden müssten. In einem großen Verband gebe es
                                immer unterschiedliche Ansichten, wie Vorgänge wie der NFDI-Prozess ablaufen
                                sollten. Schelbert fragt diesbezüglich, inwiefern Fachgrenzen als Kriterium
                                für den Zuschnitt eines NFDI-Konsortiums gesehen würden. Dröge erwidert,
                                dass es in seinen Augen vielmehr um methodische Zusammenhänge gehen müsse.
                                Schmidt fordert, eher aus der Perspektive von <em>Datenproblemen</em> nach
                                Gemeinsamkeiten zu suchen. Ein Beispiel sei die Aufbereitung von Audiodaten,
                                die auch für Zeithistoriker relevant sei. Dröge betont, dass es um die
                                Definition von Schnittstellen gehen müsse, um eine sinnvolle Arbeitsteilung
                                zwischen den verschiedenen Konsortien zu finden. Schmidt plädiert dafür,
                                diese Schnittstellen nicht personenbezogen zu denken, vielmehr müssten sie
                                sich in übergeordneten Strukturen manifestieren</p>
                        </div>
                        <div class="level2">
                            <h3>Digital Humanities im deutschsprachigen Raum (DHd)</h3>
                            <p><b>Peter Gietz</b> skizziert die Entwicklung des NFDI aus Sicht des DHd-Verbands und den
                                Erfahrungen von TextGrid und DARIAH. Für DARIAH und CLARIN stelle die NFDI
                                eine Nachhaltigkeitsperspektive dar. Der DHd-Verband habe sich mit CLARIN-D,
                                DARIAH-DE und den Akademien zusammengefunden, um in diesem Verbund den
                                Geisteswissenschaften <em>eine Stimme</em> im NFDI-Prozess zu verleihen,
                                weil zunächst nur <em>ein</em> geisteswissenschaftliches Konsortium in
                                Aussicht gestellt war. Erst im letzten Berliner Workshop sei deutlich
                                geworden, dass es auch mehrere geisteswissenschaftliche Konsortien geben
                                könne. Daraus entstand die Idee eines Cluster verschiedener, gemeinsam
                                agierender Strukturen. Der DHd sehe nach wie vor die Notwendigkeit, dass die
                                Geisteswissenschaften trotz der Beteiligung in mehreren Konsortien mit einer
                                Stimme sprechen. Deshalb biete DHd ein offenes <a
                                        href="https://nfdi.hypotheses.org/">"Geisteswissenschaftliches Forum
                                    NFDI"</a> an und könne dort als Moderator der verschiedenen Fachverbände
                                agieren. Während DHd zunächst als Partner im geplanten "NFDI4Text"
                                vorgesehen war, habe man sich als Verband aber inzwischen auf diese
                                Vermittlerrolle zurückgezogen, um so eine Governance-Struktur im Sinne eines
                                "Rats GWD" (Geisteswissenschaftlicher Daten) zu bilden. Der DHd lädt die
                                verschiedenen geisteswiss. Fachverbände zu einem Treffen Anfang 2019 in
                                Frankfurt ein, um diese Koordinationsrolle zu initiieren. Susanne Fontaine
                                merkt an, dass eine solche Clusterbildung die Sichtbarkeit der einzelnen
                                Beteiligten weiter marginalisiere könne, wobei Geisteswissenschaften in der
                                politischen Wahrnehmung ohnehin schon randständig seien. Gietz erwidert,
                                dass die Geisteswissenschaften sich nicht selbst marginalisieren dürften und
                                etwa im Rahmen von TextGrid durchaus Leuchtturm-Funktion hatten. Die
                                Geisteswissenschaften seien gerade auch im NFDI-Prozess weiter
                                fortgeschritten als viele andere Fächer. Brand betont, dass sich die
                                Geisteswissenschaften ihre Rolle in der Tat nicht zu gering einschätzen
                                sollten, insbesondere da das angedachte "NFDI4Culture" nicht von oben
                                geplant werde, sondern offenkundig ein Wunsch der hier versammelten Nutzer
                                und Wissenschaftler sei. López ergänzt, dass die sichtbar werdende Community
                                beeindruckend sei, und man sich nicht durch politische Strukturen
                                abschrecken lassen dürfe, die naturgemäß kein fachwissenschaftliches
                                Hintergrundwissen mitbringen würden.</p>
                        </div>

                        <div class="level2">
                            <h3>NFDI-Konsortium zu Editionen, Textsammlungen und Wörterbüchern</h3>
                            <p><b>Torsten Schrade</b> spricht für die Mainzer Akademie (AdW) und ihre Rolle im (formal noch unbenannten) "NFDI4Text" und beleuchtet aus dieser Perspektive die Schnittstellen zum "NFDI4Culture". Hilfreiche Folien dazu sind unter <a href="http://forschungsinfrastrukturen.de">Forschungsinfrastrukturen.de</a> im Netz hinterlegt. CLARIN-D sei als offener Zentrumsverbund organisiert. Aus dieser Erfahrung heraus zeige sich die Frage nach Standorten – Konsortien müssten immer auch verortet sein. CLARIN-D habe eine enorme Erfahrung im Bereich webbasierter Dienste gesammelt. Die Frage sei, wie diese Erfahrungen auch für andere Strukturen genutzt werden könnten. DARIAH-DE zeichne sich durch die Vielfältigkeit des Verbunds aus, in dem viele Universitäten und außeruniversitäre Partner versammelt seien. Er erwähnt die <em>DARIAH working papers</em> als gutes Beispiel der Dissemination.<br/>
                                Schwerpunkt in der Akademie seien Editionen, Sammlungen und Wörterbücher. Als Beispiele für funktionierende Tools und Services in diesem Bereich nennt er <em>ediarum</em>, das <em>Cultural Heritage Framework</em> und <em>correspSearch</em>. Diese Services hätten sich in vielen Projekten als hilfreich erwiesen und seien damit prädestiniert für eine Berücksichtigung im NFDI. Schrade merkt an, dass es unmöglich sei, Texte von den sie tragenden Objekten zu trennen, insofern sei eine Zusammenarbeit von "NFDI4Culture" und "NFDI4Text" unumgänglich. Dabei könnten auch wesentliche Dienste gemeinsam genutzt werden. Brand hakt nach, <em>wie</em> Governance-Strukturen für "NFDI4Text" praktisch zu organisieren seien? Schrade betont, dass NFDI als Investition "in Köpfe", nicht in Hardware gedacht sei. Er erwarte die Notwendigkeit zum Einbringen von Eigenleistungen. Er gehe daher davon aus, dass sich die Mainzer Akademie mit entsprechenden Stellen und einem offenen Beratungsangebot einbringen werde. Solche Angebote könnten dann als Grundlage für Governance-Strukturen dienen.</p>
                        </div>

                        <div class="level2">
                            <h3>NFDI-Konsortium der Archäologie/Kunstgeschichte/Musikwissenschaft “NFDI4Culture”</h3>
                            <p>Ihre Motivation zum Engagement im NFDI sieht <b>Dörte Schmidt</b> im möglichen Nutzen für die musikwissenschaftliche Community. Die Musikwissenschaft <em>habe</em> Daten, die auch für Entwickler interessant seien. Es gebe eine Waage zwischen <em>Sichern</em> und <em>Weiterentwickeln</em>, die wichtig einzuhalten sei. Die Suche nach dem, was "wir" besonders gut können, um uns so im NFDI positionieren zu können, habe daher zu den Fragen nach Medialität und Materialität geführt. Der gegenwärtige Workshop habe zum Ziel, die versammelte Community zur Plausibilität dieses Kulturgut-bezogenen Ansatzes zu befragen. Ein weiterer, wichtiger Aspekt sei die Rechte-Frage, die als Querschnittsthema zwar alle betreffe, die aber aufgrund der teils besonders komplexen Anforderungen etwa hinsichtlich angebundener Aufführungen in unserem Kontext am besten aufgehoben sein dürfte. Als dritte relevante Gemeinsamkeit identifiziert sie die dezentrale Forschungslandschaft, die besondere Herausforderungen an eine gemeinsame Datensicherung auch im Hinblick auf die zu beteiligenden Gedächtnisinstitutionen mit sich bringt. Dabei sei eine Balance zu finden zwischen den übergreifenden Anforderungen der Datensicherung und den individuellen Anforderungen bzgl. der Datenmodelle/-strukturen und der Nutzung der Daten. Hier gebe es große Erfahrungen aus einschlägigen Forschungsprojekten. Aus Schmidts Sicht sei zu prüfen, was genau für die beteiligten Communities angeboten werden könnte, wie die Sichtbarkeit im größeren Prozess sichergestellt werden könnte, und wie sich auch in Zukunft neue Konsortialpartner mit entsprechender Sichtbarkeit einbinden ließen.
                            </p>
                        </div>

                    </div>


                    <div class="level1">
                        <h2>Sektion 4: Planung weiterer Schritte bei der Organisation eines Konsortiums und der Abstimmung mit weiteren Akteuren; Überlegungen zu den konkreten Aufgaben bei der Vorbereitung eines gemeinsamen Antrags</h2>
                        <ul class="moderators">
                            <li>Dr. Barbara Wiermann, Sächsische Landesbibliothek — Staats- und Universitätsbibliothek Dresden (SLUB)</li>
                            <li>Prof. Dr. Andreas Münzmay, Musikwissenschaftliches Seminar Detmold/Paderborn</li>
                        </ul>
                        <p>Wiermann fragt das Plenum, ob der aufgezeigte Ansatz, sich am (materiellen wie immateriellen)
                            <b>Kulturgut</b> zu orientieren, trage. Förtsch verweist darauf, dass dabei
                            der öffentliche Auftrag an die Geisteswissenschaften als Argument anzuführen
                            sei, da darüber bereits Legitimation auch für eine Beteiligung am NFDI erbracht
                            sei. Gietz betont die anvisierte Dynamik der Konsortien, um damit eventuell auch
                            die Fächer Archäologie und Geschichtswissenschaft einzubinden. Üblich sei eine
                            Trennung der Forschung vom Cultural Heritage-Bereich, die aber laut Schmidt in
                            der Musikwissenschaft so nicht gegeben sei – hier sei eine Zusammenarbeit schon
                            immer üblich gewesen. Beide betonen, dass auch nachträglich Konsortien in ihrem
                            Zuschnitt anpassbar sein müssen, dass also auch neue Konsortial-Partner mit
                            versorgt werden können. Allerdings sei es wichtig, überhaupt einen Anfang zu
                            finden.<br /> Christian Bracht stellt die Arbeit von Bildarchiv Foto Marburg
                            vor, die bereits eine Verbindung von Archiven und Wissenschaft biete, und die
                            sich ideal für eine Einbindung in "NFDI4Culture" eigne. Barbara Alge merkt an,
                            dass der Prozess nicht zu sehr museal gedacht werden dürfe, sondern dass es
                            immer auch um die wissenschaftliche Auswertbarkeit gehen müsse. Überdies seien
                            nationale Grenzen auch im Hinblick auf Dienste wie Spotify nicht
                            durchsetzungsfähig. Schmidt verweist darauf, dass der Begriff "Kulturerbe" als
                            Übersetzung von "Cultural Heritage" etabliert sei, und trotz aller
                            Schwierigkeiten des Begriffs im politischen Prozess notwendig sei. Nur dieser
                            Begriff biete die für dieses Konsortium so spezifische Verbindung von
                            Beforschtem und Forschung.<br /> Moritz Kelber fragt Schmidt zu der auf einer
                            Folie erwähnten Einbindung der Musikwirtschaft. Schmidt erwidert, dass man sich
                            in einem durchökonomisierten Feld bewege, in dem es große Player wie Spotify
                            gebe, aber auch kleine Player wie spezialisierte Verlage. Die Musikwissenschaft
                            kommuniziere immer auch mit Verlagen etc. und nicht nur wissenschaftsintern.
                            Auch durch rechtliche Rahmenbedingungen sei man stets zum Dialog mit
                            Rechteinhabern genötigt. Hieraus ergebe sich der Bedarf nach einer
                            (möglicherweise zentralen) Klärung solcher Erfordernisse, da diese kaum sinnvoll
                            individuell zu verhandeln seien. Nur so könne verhindert werden, dass bestimmte
                            Gegenstände nicht beforscht würden.<br /> Axel Berndt verweist auf die
                            Notwendigkeit der Berücksichtigung ingenieurwissenschaftliche Fragestellungen,
                            etwa hinsichtlich Reverse Engineering bei Musikinstrumenten. Dominik Leipold
                            merkt an, dass das <em>Fach</em> Musikwissenschaft ja nicht deckungsgleich mit
                            dem vorgestellten "NFDI4Culture" sei, so dass sich das Fach auf mehrere
                            Konsortien aufteilen müsse. Zu fragen sei also, ob eine Beteiligung am
                            "NFDI4Text" sinnvoll sei, etwa um Briefeditionen abzubilden? Schmidt betont
                            erneut, dass es eher um inhaltliche Fragestellungen geht, und das derartige
                            Fragestellungen selbstverständlich auch in anderen Konsortien zu behandeln
                            seien. Dies sei gerade der Grund, den engen Austausch zu suchen.<br /> Jessica
                            Stegemann fragt, wie sich (Universitäts-)Bibliothekare einbinden ließen, da
                            diese die direkten Ansprechpartner der Wissenschaftler vor Ort seien. Es mangele
                            an Kontaktmöglichkeiten, die aber laut Schmidt ein zentraler Aspekt des
                            geplanten NFDI seien. Förtsch stellt klar, dass es für sinnvolle Konsortien eine
                            Mischung aus Querschnittsthemen und spezifischen Dingen geben müsse, wobei
                            letztere ihre Relevanz nachweisen müssten. Nötig seien ebenso sinnvolle
                            Kommunikationsstrukturen. Brand sieht gerade in der Kommunikation der Services
                            die Chance des NFDI, es gehe also gerade um die Schaffung von Anlaufstellen für
                            die lokalen Wissenschaftler. Hier könnten die Bundesländer eine wichtige Rolle
                            spielen, da diese in die Fläche hineinwirken könnten.<br /> Frédéric Döhl von
                            der DNB weist darauf hin, dass aktuelle Entwicklungen in den Bereichen Recht und
                            Gedächtnisinstitutionen mittelfristig große Herausforderungen im Bereich
                            digitaler Ressourcen als Primär- und Sekundärquellen und damit als Basis für
                            digitale Forschung und Forschungsdaten zur Gegenwartsmusikkultur mit sich zu
                            bringen drohen. So drohe z.B. im aktuellen EU-Gesetzgebungsverfahren zu einer
                            Richtlinie für das Urheberrecht im digitalen Binnenmarkt u.a., dass große Teile
                            der Bestände der deutschen Gedächtnisinstitutionen den Digital Humanities für
                            Text and Data Mining (TDM) entzogen werden (Art. 3 der RL-Entwurfs) und zentrale
                            Quellenpools wie Video- und Audioportale versanden (Art. 13: Uploadfilter). Dies
                            sei wesentlich auch für die digitale Musikwissenschaft, da z.B. (häufig
                            unauthorisiert erstellte) Handyvideoaufnahmen im Performancebereich von
                            Avantgarde bis Broadway heute oft das einzige verfügbare audio-visuelle
                            Quellenmaterial seien. Auch auf die bislang von den Gedächtnisinstitutionen nur
                            eingeschränkt betriebene und gerade in multimedialen Kontexten ebenso schwierige
                            wie aufwändige Webarchivierung wird hingewiesen sowie auf die damit verbundene,
                            noch ungeklärte Frage, was eigentlich originär digitales immaterielles – und
                            damit zu bewahrendes – Kulturerbe sei. All das unterstreiche die Wichtigkeit
                            eines konzertierten Auftretens wie im geplanten Konsortium NFDI4Culture
                            anvisiert, u.a. eben, um im politischen Prozess auf derartige Folgen für die
                            Geisteswissenschaften aufmerksam machen zu können. Denn hier würden die
                            Anforderungen der Geisteswissenschaften im Allgemeinen und der Musikwissenschaft
                            im Besonderen bislang nicht ausreichend gehört.</p>

                        <p>Wiermann bittet die Anwesenden, in ihren Augen tatsächlich erforderliche <b>Dienste und
                            Services</b> zu nennen. Für Schwindt ist es unzureichend, hier nur
                            Schlagworte zu sammeln, sondern Anforderungen und Bedingungen seien stets
                            mitzunennen. Dafür sind aber laut Münzmay und Schmidt die Antragsbedingungen
                            noch zu unklar, so dass zunächst eine erste Sammlung von Themen genüge, die dann
                            natürlich im weiteren Prozess ausgearbeitet werden müssten. Stadler verweist
                            darauf, dass sich vermutlich nicht alles aufnehmen lasse. Die Schwierigkeit
                            liege eher darin, sich zu fokussieren, um die tatsächlich übergreifend
                            relevanten Dinge thematisieren zu können. Er wünscht sich daher einen
                            transparenten Antragsprozess und eine weitere Beteiligung der Community. Schrade
                            regt den Blick in die <a
                                    href="https://www.gwk-bonn.de/fileadmin/Redaktion/Dokumente/Papers/NFDI.pdf"
                            >Bund-Länder-Vereinbarung</a> an, insbesondere § 5 zu den Förderkriterien.
                            Um die dort genannten Kriterien in einem Antrag umzusetzen, sei es sinnvoll, den
                            Forschungsdatenlebenszyklus aus Perspektive der verschiedenen Fächer in den
                            Blick zu nehmen.<br /> Es zeigt sich rasch, dass der Versuch, gemeinsame Bedarfe
                            zu sammeln (etwa hinsichtlich Beratung, Entwicklung von Standards und
                            Qualitätssicherung) und fachspezifische Dienste (wie u.a. RISM, Edirom,
                            Normdaten zu musikalischen Werken) sowie generische Dienste (wie correspSearch)
                            im Rahmen des Workshops nicht zu leisten ist, sondern durch eine separate
                            Erhebung umgesetzt werden sollte. Kelber fragt nach, ob man bereits in der
                            ersten Runde des NFDI teilnehmen wolle, oder ob es sinnvoller wäre, ein weiteres
                            Jahr abzuwarten. Schmidt empfiehlt aber, den Zusammenhang der genannten
                            Konsortien (NFDI4Culture, NFDI4Text, NFDI der Historiker) herauszustellen und
                            quasi gemeinsam einzureichen.<br /> Neben der Schulungsebene wird in der
                            Diskussion der Bedarf an Einzelberatung auf verschiedensten Ebenen deutlich.
                            Auch wenn nicht alle Fachwissenschaftler aktiv in den NFDI integriert werden
                            könnten, ließen sich ihre Bedarfe so aber doch berücksichtigen. Auf diese Weise
                            könnten gleichzeitig die zu berücksichtigenden Dienste im kontinuierlichen
                            Dialog mit der Community evaluiert werden. Stegemann fragt an, ob eine
                            Arbeitsteilung zwischen kleineren und größeren Repositorien denkbar sei, dass
                            also Daten zu einem zu benennenden Zeitpunkt an zentrale "Datensammelstellen"
                            für die weitere Archivierung übergeben werden können. Hier gebe es aber einen
                            Befarf zur Datenkuration. Stefanie Aquavella-Rauch regt an, sich an dem
                            bestehenden "Positionspapier der Fachgruppe Digitale Musikwissenschaft für die
                            Leitlinien der DFG zum Umgang mit Forschungsdaten" zu orientieren.<br /> Schmidt
                            betont die Notwendigkeit, konkreter zu identifizieren, wer was und wie bereits
                            anbietet, sowie was sich davon in NFDI überführen ließe. Schwindt regt an, diese
                            Arbeit an die einzelnen zu beteiligenden Institutionen und Partner zu
                            delegieren, da dies mit der nötigen Substanz im Plenum kaum zielführend
                            umzusetzen sei. Schrade schlägt fachbezogene "Schreibgruppen" vor, die mit einer
                            festen Deadline eine Übersicht für ihr jeweiliges Fach erstellen. Im Anschluss
                            daran müssten diese Schreibgruppen ihre Ergebnisse harmonisieren und auf die
                            dann hoffentlich verfügbare Ausschreibung anzupassen. Nötig sei aber eine
                            zentrale Koordination dieser drei Schreibgruppen. Eine solche Koordination habe
                            sich ja bereits durch die Organisation dieses Workshops als zielführend
                            erwiesen. Man ist sich einig, weitere Informationen an zentraler Stelle (z.B. in
                            einem Google-Doc) für alle einsichtig zusammenzutragen. Die Organisatoren
                            erklären sich bereit, in diesem Sinne aktiv zu werden.</p>

                    </div>
                    <div class="level1">
                        <h2>Zusammenfassung</h2>
                        <ul class="speakers">
                            <li>Dr. Ania López</li>
                            <li>Prof. Dr. Dörte Schmidt</li>
                            <li>Dipl. Wirt.-Inf. Daniel Röwenstrunk</li>
                            <li>Prof. Dr. Joachim Veit</li>
                        </ul>
                        <p>
                            López betont abschließend die Bedeutung von § 5c der Bund-Länder-Vereinbarung, d.h. der Verankerung des Konsortiums in der jeweiligen Fachgemeinschaft, die immer als prozesshaft zu verstehen sei. Gute Voraussetzungen dafür seien im "NFDI4Culture" offensichtlich gegeben. Wichtig sei überdies, eine gute Kommunikationsstrategie zu entwickeln, insbesondere auch hinsichtlich des Zusammenhangs mit anderen Konsortien. Sie regt daher weitere Workshops zur Planung des "NFDI4Culture" an. Wichtig seien auch Überlegungen zu Sollbruchstellen und zum Zuschnitt des Konsortiums, der die passende Community widerspiegeln müsse. Sie regt außerdem eine stärkere Berücksichtigung der internationalen Anbindung an, die in den letzten beiden Tagen nicht ausreichend zur Sprache gekommen sei.
                        </p>
                        <p>Schmidt ist überzeugt, dass der Zuspruch, der sich u.a. in den Anmeldezahlen des Workshops zeige, die gute Ausgangslage für ein "NFDI4Culture" bestätige. Musikwissenschaft sei in einer guten Lage, um sich erfolgreich im NFDI einzubringen. Sie bittet um aktive Beteiligung an den genannten Schreibgruppen und weitere Vorschläge.</p>
                        <p>Röwenstrunk bedankt sich bei den Anwesenden für die aktive Beteiligung und bedauert, dass nicht mehr Zeit für Diskussionen zur Verfügung stand. Er sekundiert ein kollaboratives Weiterarbeiten und steht für eine weitere Koordination zur Verfügung. Nötig sei ein offensiver Umgang mit den eigenen Möglichkeiten, wobei die von Frau López thematisierte internationale Anbindung intern völlig selbstverständlich sei.
                        </p>
                        <p>Veit betont die zentrale Rolle der Kommunikation, die künftig intensiviert werden müsse. Er zeigt die in der Bund-Länder-Vereinbarung deutlich gewordene Gesamtstruktur des NFDI auf und verdeutlicht damit, dass die Einzel-Konsortien nur einen begrenzten Einfluss haben werden, der sich nur durch eine gute Zusammenarbeit über Konsortiumsgrenzen hinweg verbessern lasse. Daher verweise er auch nochmals auf das Angebot des DHd, den Dialog zwischen den Fachverbänden zu moderieren. Er moniert, dass die formulierten Bedarfe noch sehr vage seien, und dass man im Sinne Torsten Schrades sehr viel konkreter planen müsse. Er regt außerdem an, die "lebenden" Daten stärker als bislang in den Blick zu nehmen. Jede digitale Auseinandersetzung erzeuge Daten, die wiederum Gegenstand weiterer Forschungen außerhalb der ursprünglichen würden, wodurch sich abermals komplexe rechtliche Probleme ergäben.
                            Er plädiert dafür, nicht nur aus der Gesamtfachperspektive auf die Konsortienbildung schauen, sondern vielmehr die einzelnen Schwerpunkte und Personen der verschiedenen Konsortien in den Blick zu nehmen und das Vorgehen daran auszurichten (gemäß Schrades Frage "Welche Leute in welchen Communities sind für meine Fragen wichtig?"). Auch die mit verschiedenen Gesichtspunkten thematisierten Querschnittsfragen sollten weiter präzisiert werden, da sie ja gerade die gemeinsame Stärke der Geisteswissenschaften herausstellen könnten. Es blieben also vielfältige Aufgaben, die auf dem weiteren Weg zu einer Beteiligung am NFDI abgearbeitet werden müssten. Er bedankt sich abschließend bei der Landesinitiative für die nachhaltige Unterstützung, bei den Organisatoren und dem Team des Virtuellen Forschungsverbundes Edirom sowie bei allen Anwesenden und Beiträgern und fordert nachdrücklich zu einer weiteren Beteiligung am NFDI-Prozess, etwa bei zukünftigen Workshops, auf.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'minutes',
        components: {
        }
    }
</script>

<style scoped>

    h1 {
        margin-bottom: 4ex;
        text-align: center;
    }

    h2 {
        font-size: 1.5em;
    }

    .minutes {
        background-color: #ffffff;
        border-radius: 0 0 7px 7px;
        padding: 3em;
        color: #1b1e21;
        text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .2);
        z-index: 5;
        text-align: left;
    }

    .subtitle {
        text-align: center;
        margin-top: -3em;
        margin-bottom: 3em;
    }

    div.level1 {
        margin-top: 4ex;
    }

    ul {
        font-size: .9rem;
        list-style-type: none;
        margin: 0 0 1rem 1rem;
        padding: 0;
        padding-top: 1rem;
        color: #666666;
    }

    ul + ul {
        margin-top: .5rem;
    }

    ul.speakers:before {
        content: 'Redende:';
        font-weight: bold;
    }

    ul.moderators:before {
        content: 'Moderation:';
        font-weight: bold;
    }


</style>